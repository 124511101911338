import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  Paper,
  TextField,
  Typography,
  IconButton,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import ParserSwitch from '../uikits/ParserSwitch';

const PageProxy = () => {
  const [list, setList] = useState([]);
  const [ip, setIp] = useState('');
  const [comment, setComment] = useState('');
  const [error, setError] = useState(false);
  const [commentError, setCommentError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [rawList, setRawList] = useState('');

  function validateIPAddress(ip) {
    if (/^((25[0-5]|(2[0-4]|1\d|[1-9]|)\d)\.?\b){4}$/.test(ip)) {
      const elementsList = rawList
        .split('\n')
        .map((element) => element.split(' #')[0]);
      if (elementsList.includes(ip)) {
        setError(true);
        return setErrorMessage('This IP already exists');
      }

      setErrorMessage('');
      return setError(false);
    }
    setErrorMessage('Incorrect IP address');
    return setError(true);
  }

  useEffect(() => {
    if (ip) {
      validateIPAddress(ip);
    } else {
      setError(false);
      setErrorMessage('');
    }
  }, [ip, rawList]);

  useEffect(() => {
    setTimeout(() => setCommentError(false), 2000);
  }, [commentError]);

  const fetchList = async () => {
    const response = await fetch(
      `https://sq7.ee/download/proxy`
    );
    const text = await response.text();
    setRawList(text);
    setList(text.split('\n'));
  };

  useEffect(() => {
    fetchList();
  }, []);

  const handleIpChange = (event) => {
    setIp(event.target.value);
  };

  const handleCommentChange = (event) => {
    setComment(event.target.value.replaceAll(' ', ''));
  };

  const addIp = async () => {
    await fetch(`https://sq7.ee/download/proxy`, {
      method: 'post',
      body: JSON.stringify({
        ip,
        comment,
      }),
      headers: {
        'Content-type': 'application/json; charset=UTF-8',
      },
    });
    setComment('');
    setIp('');
    await fetchList();
  };
  

  const deleteIp = (ip) => {
    return async () => {
      await fetch(`https://sq7.ee/download/proxy?ip=${ip}`, {
        method: 'delete',
      });
      await fetchList();
    };
  };

  const handleLogout = () => {
    localStorage.clear();
    history.push('/login');
  };

  return (
    <Box
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      <Box
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          maxHeight: 40,
          width: 1245,
          padding: '0 5px 0 5px',
          marginBottom: 40,
          marginTop: 24,
        }}
      >
        <Box
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'flex-start',
          }}
        >
          <span
            style={{
              marginRight: 10,
              marginTop: 10,
            }}
          >
            IP
          </span>
          <TextField
            variant="outlined"
            style={{ marginRight: 10 }}
            error={error}
            helperText={errorMessage}
            size="small"
            value={ip}
            onChange={handleIpChange}
            placeholder="IP address"
          />
          <span
            style={{
              marginTop: 10,
              marginRight: 10,
            }}
          >
            Comment
          </span>
          <TextField
            variant="outlined"
            style={{ marginRight: 10 }}
            size="small"
            value={comment}
            onChange={handleCommentChange}
            error={commentError}
            helperText={commentError ? 'enter only ip' : ''}
            placeholder="Comment"
          />
          <div>
            <Button
              variant="contained"
              style={{ marginRight: 5 }}
              disabled={error || !ip}
              onClick={addIp}
            >
              Add
            </Button>
          </div>
        </Box>
        <Box style={{ display: 'flex' }}>
          <ParserSwitch />
          <Button
            color="primary"
            style={{
              minWidth: '5rem',
              minHeight: '3.12rem',
              color: '#4A5568',
              backgroundColor: '#F7FAFC',
              border: '0.08rem solid #EDF2F7',
              marginLeft: '0.8rem',
              whiteSpace: 'nowrap',
            }}
            onClick={handleLogout}
          >
            Log out
          </Button>
        </Box>
      </Box>
     <Box style={{
        width: 1245,
        margin: '0 auto',
      }}>
        <Typography style={{fontSize: 20, marginBottom: 10, alignSelf: 'start'}}>Proxy servers</Typography>
        <p>sq7-proxy-neil  (nyc1) - 165.227.80.193</p>
        <p>sq7-proxy-templeton  (nyc3) - 159.203.95.252</p>
        <p>sq7-proxy-bloom (sf02) - 138.68.44.9</p>
        <p>sq7-proxy-rob (nyc1) - 161.35.104.37</p>
        <p>usaproxy (nyc3) - 159.203.76.243</p>
        <Typography style={{fontSize: 20, marginBottom: 10, alignSelf: 'start'}}>Whitelisted IPs</Typography>
      </Box>
      <Paper
        style={{
          width: 1245,
          padding: 15,
          margin: '0 auto',
        }}
      >
        <Box>
          {list
            .filter((ip) => ip !== '')
            .map((ip) => {
              return (
                <Box style={{ display: 'flex' }}>
                  <Typography
                    style={{ fontSize: 20, marginRight: 10 }}
                  >
                    {ip}
                  </Typography>
                  <IconButton onClick={deleteIp(ip)}>
                    <CloseIcon />
                  </IconButton>
                </Box>
              );
            })}
        </Box>
      </Paper>
    </Box>
  );
};

export default PageProxy;
